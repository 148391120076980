import React from 'react'
import PropTypes from 'helpers/proptypes'
import { useLocale } from 'hooks/locales/useLocale'
import styled from 'styled-components'
import { Box } from '@vizeat/components/es6/components/Box'
import { Heading } from '@vizeat/components/es6/components/Heading'

export const StyledHeading = styled(({ ...props }) => <Heading as='h2' {...props} />)`
  margin-bottom: 24px;
  font-size: 24px;
  line-height: 1.2em;

  ${({ theme }) => theme.media.tablet`
    font-size: 28px;
  `}
`

export function StyledSectionTitle({ showCustomMarks, children, ...props }) {
  const { locale } = useLocale()

  return (
    <StyledHeading customEndingMarks={showCustomMarks} locale={locale} {...props}>
      {children}
    </StyledHeading>
  )
}

StyledSectionTitle.propTypes = {
  showCustomMarks: PropTypes.bool,
  children: PropTypes.node.isRequired,
}

StyledSectionTitle.defaultProps = {
  showCustomMarks: false,
}

export function StyledSection({ children, hasCarousel, ...styledProps }) {
  return (
    <Box
      maxWidth={1071}
      margin='0 auto'
      padding={{ default: hasCarousel ? '18px 0 18px 18px' : '18px', tablet: '32px' }}
      {...styledProps}
    >
      {children}
    </Box>
  )
}

StyledSection.propTypes = {
  children: PropTypes.node.isRequired,
  hasCarousel: PropTypes.bool,
}

StyledSection.defaultProps = {
  hasCarousel: false,
}

export function StyledSectionWrapper({ children, ...styledProps }) {
  return (
    <Box bg='lighterGray' padding='32px 0' {...styledProps}>
      {children}
    </Box>
  )
}

StyledSectionWrapper.propTypes = {
  children: PropTypes.node.isRequired,
}

export function SeparatorCurve() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='1007'
      height='69'
      fill='none'
      viewBox='0 0 1007 69'
      preserveAspectRatio='xMidYMid meet'
      css={`
        display: block;
        margin: 0 auto;
        max-width: 100%;

        ${({ theme }) => theme.media.tablet`
          margin: 32px auto;
        `}

        &:nth-of-type(even) {
          transform: rotate(180deg);
        }
      `}
    >
      <path
        stroke='#FD7854'
        strokeWidth='4'
        d='M1008 24.854c-60.93 36.392-129.417 44.81-165.314 41.108-52.145-5.378-84.449-28.86-79.568-50.444 2.579-11.402 16.276-18.475 25.599-9.334 10.132 9.934 0 27.898-17.353 34.243-103.061 37.685-245.421 33.96-362.274-2.78C291.953.815 155.923.14 76.572 24.853L1 48.297'
      />
    </svg>
  )
}
