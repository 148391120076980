import React from 'react'
import PropTypes from 'helpers/proptypes'
import { Section } from '@vizeat/components/es6/components/Section'
import { RichText } from 'prismic-reactjs'
import { SECTION_BG_COLOR, StyledSectionWrapper, StyledSectionTitle } from './styled'

const { default: defaultBackgroundColor, ...sectionBgColors } = SECTION_BG_COLOR

export function InstructionsSection({
  staticFields: { anchor_id: anchorId, background_color: backgroundColor, heading, instructions },
}) {
  return (
    <StyledSectionWrapper bg={sectionBgColors[backgroundColor] || defaultBackgroundColor} id={anchorId}>
      <Section innerContainerStyles='text-align: left;'>
        <StyledSectionTitle title={heading} />
        <RichText render={instructions} />
      </Section>
    </StyledSectionWrapper>
  )
}

export const InstructionsSectionPropTypes = {
  staticFields: PropTypes.shape({
    anchor_id: PropTypes.string,
    background_color: PropTypes.oneOf(Object.keys(sectionBgColors)),
    heading: PropTypes.string,
    instructions: PropTypes.arrayOf(PropTypes.shape({})),
  }),
}

InstructionsSection.propTypes = InstructionsSectionPropTypes.isRequired
