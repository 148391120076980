import React from 'react'
import PropTypes from 'helpers/proptypes'
import { SECTION_BG_COLOR, StyledSectionWrapper, StyledButtonLink, StyledSectionTitle } from './styled'
import { Section } from '@vizeat/components/es6/components/Section'
import { Heading } from '@vizeat/components/es6/components/Heading'
import { Grid } from '@vizeat/components/es6/components/Grid'
import { Box } from '@vizeat/components/es6/components/Box'

const { default: defaultBackgroundColor, ...sectionBgColors } = SECTION_BG_COLOR

export function FaqSection({
  staticFields: {
    anchor_id: anchorId,
    button_text: buttonText,
    button_url: buttonUrl,
    background_color: backgroundColor,
    subtitle,
    title,
  },
  items,
}) {
  // rel attribute set everywhere to avoid crawlers following external links
  return (
    <StyledSectionWrapper id={anchorId} bg={sectionBgColors[backgroundColor] || defaultBackgroundColor}>
      <Section>
        {(title || subtitle) && (
          <Box mb='24px'>
            {title && <StyledSectionTitle title={title} />}
            {subtitle && (
              <Heading as='h3' type='medium'>
                {subtitle}
              </Heading>
            )}
          </Box>
        )}
        <Grid gap='16px' templateColumns={{ tablet: 'repeat(2, 1fr)' }}>
          {items.map(({ anchor_text: anchorText, anchor_url: anchorUrl }) => (
            <StyledButtonLink
              key={anchorUrl}
              href={anchorUrl}
              clearPrimary
              allowTextWrap
              rel='noopener noreferrer nofollow'
              css={`
                display: block;
                text-align: left;
                border-bottom: ${({ theme }) => `${theme.borders.sm} ${theme.colors.lightGray}`};
              `}
            >
              {anchorText}
            </StyledButtonLink>
          ))}
        </Grid>
        {buttonText && (
          <Box width='100%' mt='32px' textAlign='center'>
            <StyledButtonLink large rounded href={buttonUrl} rel='noopener noreferrer nofollow'>
              {buttonText}
            </StyledButtonLink>
          </Box>
        )}
      </Section>
    </StyledSectionWrapper>
  )
}

FaqSection.propTypes = {
  staticFields: PropTypes.shape({
    anchor_id: PropTypes.string,
    button_text: PropTypes.string,
    button_url: PropTypes.string,
    background_color: PropTypes.oneOf(Object.keys(sectionBgColors)),
    subtitle: PropTypes.string,
    title: PropTypes.string,
  }).isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      anchor_text: PropTypes.string,
      anchor_url: PropTypes.string,
    }),
  ).isRequired,
}
