import React from 'react'
import PropTypes from 'helpers/proptypes'
import styled, { css } from 'styled-components'
import { useMediaQuery } from '@vizeat/components/es6/components/MediaQuery'
import { useLocale } from 'hooks/locales/useLocale'
import {
  IconBankCard,
  IconBell,
  IconCertification,
  IconChefDishHat,
  IconCrown,
  IconCulturalImmersion,
  IconGiftCard,
  IconHostSeating,
  IconKey,
  IconPaperAirplane,
  IconPersonHeartFilled,
  IconPin,
  IconProfessionalChef,
} from '@vizeat/components/es6/assets/icons'
import { Box } from '@vizeat/components/es6/components/Box'
import { Heading } from '@vizeat/components/es6/components/Heading'
import { ButtonLink } from 'components/shared/ButtonLink'
import { Grid } from '@vizeat/components/es6/components/Grid'
import { CarouselRtlSensitive } from 'components/shared/carousels'
import { rgba } from 'polished'

export const ICONS = {
  bank_card: IconBankCard,
  bell: IconBell,
  certification: IconCertification,
  chef: IconProfessionalChef,
  crown: IconCrown,
  cultural_immersion: IconCulturalImmersion,
  default: IconChefDishHat,
  dish_and_hat: IconChefDishHat,
  gift_card: IconGiftCard,
  host_seating: IconHostSeating,
  key: IconKey,
  paper_airplane: IconPaperAirplane,
  person_heart_filled: IconPersonHeartFilled,
  pin: IconPin,
}

export const SECTION_BG_COLOR = {
  whitish: 'white',
  grayish: 'lighterGray',
  greenish: 'pressGreen',
  default: 'white',
}

export const HERO_HEIGHT = { MOBILE: '540px', TABLET: '660px' }

export const heroSectionStyle = css`
  &:after {
    background: linear-gradient(180deg, rgba(16, 15, 15, 0) 0%, rgba(0, 0, 0, 0.4) 87.5%);
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 316px;
  }

  & > div:last-child {
    width: 100%;
  }
`

export function StyledSectionWrapper({ children, ...styledProps }) {
  return (
    <Box padding='32px 0' {...styledProps}>
      {children}
    </Box>
  )
}

StyledSectionWrapper.propTypes = {
  children: PropTypes.node.isRequired,
}

export function StyledSectionTitle({ title, ...props }) {
  const { locale } = useLocale()
  if (!title) return null
  return (
    <Heading
      as='h2'
      locale={locale}
      mt='0'
      mb='24px'
      fontSize='28px'
      fontWeight='bolder'
      line-height='1.2em'
      customEndingMarks
      {...props}
    >
      {title}
    </Heading>
  )
}

StyledSectionTitle.propTypes = {
  title: PropTypes.string,
}

StyledSectionTitle.defaultProps = {
  title: undefined,
}

export function StyledSectionSubtitle({ children, ...props }) {
  if (!children) return null
  return (
    <Heading as='h3' fontSize='22px' fontWeight='bolder' mb='20px' {...props}>
      {children}
    </Heading>
  )
}

StyledSectionSubtitle.propTypes = {
  children: PropTypes.node,
}

StyledSectionSubtitle.defaultProps = {
  children: undefined,
}

export const styledCarouselDots = css`
  button {
    background: ${({ theme }) => rgba(theme.colors.darkGreen, 0.25)};

    &[aria-selected='true'] {
      background: ${({ theme }) => theme.colors.darkGreen};
    }
  }
`

export const StyledButtonLink = styled(ButtonLink)`
  text-wrap: balance;

  ${(props) => props.theme.media.tablet`
    text-wrap: nowrap;
  `}
`

export function GridToCarouselWrapper({ children }) {
  const isDesktopOrHigher = useMediaQuery('desktop')

  return isDesktopOrHigher ? (
    <Grid gridTemplateColumns='repeat(4, 1fr)' gap='24px'>
      {children}
    </Grid>
  ) : (
    <CarouselRtlSensitive slideWidth='230px' cellSpacing={24}>
      {children}
    </CarouselRtlSensitive>
  )
}

GridToCarouselWrapper.propTypes = {
  children: PropTypes.node.isRequired,
}
