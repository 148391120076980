import React from 'react'
import PropTypes from 'helpers/proptypes'
import styled from 'styled-components'
import { Section } from '@vizeat/components/es6/components/Section'
import { Box } from '@vizeat/components/es6/components/Box'
import { Video } from '@vizeat/components/es6/components/Video'
import { RichText } from 'prismic-reactjs'
import { SECTION_BG_COLOR, StyledSectionWrapper, StyledSectionTitle } from './styled'

const { default: defaultBackgroundColor, ...sectionBgColors } = SECTION_BG_COLOR

const StyledVideo = styled(Video)`
  max-width: 100%;
  margin: 0 auto;
  iframe {
    padding: 8px;
    border-radius: ${({ theme }) => theme.radii.md};
    box-shadow: ${({ theme }) => theme.shadows.sm};
  }
`

export function VideoSection({
  staticFields: { anchor_id: anchorId, background_color: backgroundColor, text, title, video_url: videoUrl },
}) {
  if (!videoUrl) return null

  return (
    <StyledSectionWrapper id={anchorId} bg={sectionBgColors[backgroundColor] || defaultBackgroundColor}>
      <Section textAlign={{ default: 'center', tablet: 'left' }}>
        {title && text && (
          <Box mb={text ? '24px' : '32px'}>
            <StyledSectionTitle title={title} />
            <RichText render={text} />
          </Box>
        )}
        <StyledVideo url={videoUrl} />
      </Section>
    </StyledSectionWrapper>
  )
}

VideoSection.propTypes = {
  staticFields: PropTypes.shape({
    anchor_id: PropTypes.string,
    background_color: PropTypes.oneOf(Object.keys(sectionBgColors)),
    title: PropTypes.string,
    text: PropTypes.shape({}),
    video_url: PropTypes.string,
  }).isRequired,
}
