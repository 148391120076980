import React from 'react'
import PropTypes from 'prop-types'
import { css } from 'styled-components'
import { NextImage } from 'components/shared/NextImage'
import { Flex } from '@vizeat/components/es6/components/Flex'
import { Box } from '@vizeat/components/es6/components/Box'
import { Heading } from '@vizeat/components/es6/components/Heading'
import { Text } from '@vizeat/components/es6/components/Text'
import { Section } from '@vizeat/components/es6/components/Section'
import { SECTION_BG_COLOR, StyledSectionWrapper, StyledSectionTitle, GridToCarouselWrapper } from './styled'

const { default: defaultBackgroundColor, ...sectionBgColors } = SECTION_BG_COLOR

function HostCard({ alt, name, description, image }) {
  return (
    <Flex flexDirection='column' gap='16px' alignItems='center' maxWidth='220px'>
      <NextImage
        alt={alt}
        width={128}
        height={128}
        src={image}
        imageStyle={css`
          border-radius: ${({ theme }) => theme.radii.full};
        `}
      />
      <Box textAlign='center'>
        <Heading type='large'>{name}</Heading>
        <Text size='s'>{description}</Text>
      </Box>
    </Flex>
  )
}

HostCard.propTypes = {
  alt: PropTypes.string,
  image: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
}

HostCard.defaultProps = {
  alt: undefined,
}

export function HostsSection({
  staticFields: { anchor_id: anchorId, background_color: backgroundColor, title, subtitle },
  items,
}) {
  return (
    <StyledSectionWrapper id={anchorId} bg={sectionBgColors[backgroundColor] || defaultBackgroundColor}>
      <Section py='0' hasCarousel>
        <Flex flexDirection='column' mb={subtitle ? '28px' : '4px'}>
          {title && <StyledSectionTitle showCustomMarks customEndingMarks='.' title={title} />}
          {subtitle && (
            <Heading as='h3' type='medium'>
              {subtitle}
            </Heading>
          )}
        </Flex>
        <GridToCarouselWrapper>
          {items.map(({ alt, description, image, host_name: hostName }) => (
            <HostCard key={image} alt={alt} description={description} name={hostName} image={image} />
          ))}
        </GridToCarouselWrapper>
      </Section>
    </StyledSectionWrapper>
  )
}

HostsSection.propTypes = {
  staticFields: PropTypes.shape({
    anchor_id: PropTypes.string,
    background_color: PropTypes.oneOf(Object.keys(sectionBgColors)),
    title: PropTypes.string,
    subtitle: PropTypes.string,
  }).isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      alt: PropTypes.string,
      description: PropTypes.shape({}),
      image: PropTypes.string,
      host_name: PropTypes.string,
    }),
  ).isRequired,
}
