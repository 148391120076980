import React from 'react'
import PropTypes from 'helpers/proptypes'
import styled from 'styled-components'
import { useMediaQuery } from '@vizeat/components/es6/components/MediaQuery'
import { useIsRtl } from 'hooks/locales/useIsRtl'
import { Section } from '@vizeat/components/es6/components/Section'
import { StyledSectionTitle, styledCarouselDots } from './styled'
import { Box } from '@vizeat/components/es6/components/Box'
import { CarouselRtlSensitive } from 'components/shared/carousels'
import { UserReview } from '@vizeat/components/es6/components/UserReview'
import { getPrismicImage } from 'helpers/ImageManager'
import greenWave from '../../../../public/assets/images/green_wave.png'
import quote from '../../../../public/assets/images/quote.svg'

const StyledSection = styled(Section)`
  position: relative;
  background: url(${greenWave}) center center / cover no-repeat;
  background-color: ${({ theme }) => theme.colors.white};
`

const QuoteWrapper = styled(Box)`
  &:before,
  &:after {
    content: url(${quote});
    position: absolute;
    z-index: 1;
  }

  &:before {
    top: 0;
    left: 0;
    transform: scaleX(${({ isRTL }) => (isRTL ? '-1' : '1')});
  }

  &:after {
    display: none;
    right: 0;
    bottom: 24px;
    transform: rotate(180deg) scaleX(${({ isRTL }) => (isRTL ? '-1' : '1')});
  }

  ${({ theme }) => theme.media.tablet`
    &:before { display: none; }
  `}

  ${({ theme }) => theme.media.desktop`
    &:after { display: block; }
    &:before { 
      display: block; 
      top: 52px;
    }
  `}
`

export function ReviewsSection({ staticFields: { anchor_id: anchorId, title }, items }) {
  const isTabletOrGreater = useMediaQuery('tablet')
  const { isRTL } = useIsRtl()

  if (items.length === 0) return null

  return (
    <StyledSection id={anchorId} bg='white' pt='32px' textAlign='left'>
      <StyledSectionTitle title={title} />
      <QuoteWrapper position='relative' width='100%' mt='16px' isRTL={isRTL}>
        <Box mx='auto' maxWidth='650px'>
          <CarouselRtlSensitive
            loop
            autoplay
            showBottomControls
            showArrowControls={false}
            slideWidth={isTabletOrGreater ? '650px' : '100%'}
            dotStyles={styledCarouselDots}
          >
            {items.map(({ avatar_image: avatar, referral_experience: referralExperience, review_body: comment }) => (
              <UserReview
                key={avatar}
                image={getPrismicImage(avatar, '100x100')}
                comment={comment}
                subtitle={referralExperience}
              />
            ))}
          </CarouselRtlSensitive>
        </Box>
      </QuoteWrapper>
    </StyledSection>
  )
}

ReviewsSection.propTypes = {
  staticFields: PropTypes.shape({
    anchor_id: PropTypes.string,
    title: PropTypes.string,
  }).isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      avatar_image: PropTypes.string,
      referral_experience: PropTypes.string,
      review_body: PropTypes.string,
    }),
  ).isRequired,
}
