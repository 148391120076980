import React, { useMemo } from 'react'
import PropTypes from 'helpers/proptypes'
import { SECTION_BG_COLOR, StyledSectionTitle } from './styled'
import IconCheck from '@vizeat/components/es6/assets/icons/IconCheck'
import { Section } from '@vizeat/components/es6/components/Section'
import { Flex } from '@vizeat/components/es6/components/Flex'
import { ItemsList } from '@vizeat/components/es6/components/ItemsList'
import { Heading } from '@vizeat/components/es6/components/Heading'
import { RichText } from 'prismic-reactjs'

const { default: defaultBackgroundColor, ...sectionBgColors } = SECTION_BG_COLOR

export function ListSection({
  staticFields: { background_color: backgroundColor, title, subtitle, description },
  items,
}) {
  const listItems = useMemo(
    () => items.map(({ list_item_text: text }) => ({ text, icon: <IconCheck color='primary' mr='8px' /> })),
    [items],
  )

  return (
    <Section bg={sectionBgColors[backgroundColor] || defaultBackgroundColor} textAlign='left'>
      <StyledSectionTitle title={title} />
      <Flex flexDirection='column' gap='8px'>
        <Heading as='h4' type='medium' mb='0'>
          {subtitle}
        </Heading>
        <RichText render={description} />
        <ItemsList items={listItems} />
      </Flex>
    </Section>
  )
}

ListSection.propTypes = {
  staticFields: PropTypes.shape({
    background_color: PropTypes.oneOf(Object.keys(sectionBgColors)),
    description: PropTypes.arrayOf(PropTypes.shape({})),
    subtitle: PropTypes.string,
    title: PropTypes.string,
  }).isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      list_item_text: PropTypes.string,
    }),
  ).isRequired,
}
