// libs
import React from 'react'
import PropTypes from 'helpers/proptypes'
import NextHead from 'next/head'

Head.propTypes = {
  staticFields: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
  }),
}

Head.defaultProps = {
  staticFields: {
    title: undefined,
    description: undefined,
  },
}

export function Head({ staticFields: { title, description } }) {
  return (
    <NextHead>
      {title && (
        <>
          <title>{title}</title>
          <meta key='title' property='og:title' content={title} />
        </>
      )}
      {description && <meta key='description' name='description' property='og:description' content={description} />}
    </NextHead>
  )
}
