import React from 'react'
import PropTypes from 'helpers/proptypes'
import styled, { css } from 'styled-components'
import { useDefaultContents } from 'hooks/prismic'
import { SmartLink } from 'components/shared/SmartLink'
import { NextImage } from 'components/shared/NextImage'
import { Box } from '@vizeat/components/es6/components/Box'
import { Heading } from '@vizeat/components/es6/components/Heading'
import { Text } from '@vizeat/components/es6/components/Text'
import { SECTION_BG_COLOR, GridToCarouselWrapper } from './styled'
import { StyledSection, StyledSectionTitle, StyledSectionWrapper } from 'components/pages/home/styled'

const { default: defaultBackgroundColor, ...sectionBgColors } = SECTION_BG_COLOR

const StyledLink = styled(SmartLink)`
  ${({ theme }) => theme.media.desktop`
    transform: scale(1);
    transition: transform .2s ease-in;

    &:hover {
      transform: scale(1.05);
    }
  `}
`

const defaultStaticFields = {
  background_color: 'grayish',
  title: __('PopularDestinationsSection::discover eatwith in 1,100 other destinations around the world!'),
}
const defaultItems = [
  {
    alt: 'View of Paris',
    image: 1379325,
    title: __('PopularDestinationsSection::Paris'),
    subtitle: __("PopularDestinationsSection::Experience the 'art de vivre' in the City of Lights."),
    url: '/l/destinations/paris',
  },
  {
    alt: 'View of London',
    image: 1379326,
    title: __('PopularDestinationsSection::London'),
    subtitle: __('PopularDestinationsSection::Join unique pop-up events and supper clubs in this cool city.'),
    url: '/l/destinations/london',
  },
  {
    alt: 'View of Rome',
    image: 1379327,
    title: __('PopularDestinationsSection::Rome'),
    subtitle: __('PopularDestinationsSection::Enjoy a traditional Italian feast with a local Roman family.'),
    url: '/l/destinations/rome',
  },
  {
    alt: 'View of Athens',
    image: 1379330,
    title: __('PopularDestinationsSection::Athens'),
    subtitle: __('PopularDestinationsSection::The ancient city has got your every craving covered.'),
    url: '/l/destinations/athens',
  },
  {
    alt: 'View of Barcelona',
    image: 1379328,
    title: __('PopularDestinationsSection::Barcelona'),
    subtitle: __('PopularDestinationsSection::Find the best paella, tapas and sangria in town with our local hosts.'),
    url: '/l/destinations/barcelona',
  },
  {
    alt: 'View of Berlin',
    image: 1379331,
    title: __('PopularDestinationsSection::Berlin'),
    subtitle: __('PopularDestinationsSection::Discover the true foodie scene in Berlin.'),
    url: '/l/destinations/berlin',
  },
  {
    alt: 'View of Tokyo',
    image: 1320642,
    title: __('PopularDestinationsSection::Tokyo'),
    subtitle: __('PopularDestinationsSection::Say konichiwa to delicious Japanese food.'),
    url: '/l/destinations/tokyo',
  },
  {
    alt: 'View of New York',
    image: 1379329,
    title: __('PopularDestinationsSection::New York'),
    subtitle: __('PopularDestinationsSection::Experience the City That Never Sleeps like a native New Yorker.'),
    url: '/l/destinations/new-york',
  },
]

export function PopularDestinationsSection({ items, staticFields, innerSectionProps }) {
  const {
    statics: { anchor_id: anchorId, background_color: backgroundColor, title, subtitle } = {},
    repeatables = [],
  } = useDefaultContents({ staticFields, items, defaultStaticFields, defaultItems })

  if (repeatables.length === 0) return null

  return (
    <StyledSectionWrapper id={anchorId} bg={sectionBgColors[backgroundColor] || defaultBackgroundColor}>
      <StyledSection py='0' hasCarousel {...innerSectionProps}>
        {title && <StyledSectionTitle showCustomMarks>{title}</StyledSectionTitle>}
        {subtitle && (
          <Heading as='h3' type='medium' mb='16px'>
            {subtitle}
          </Heading>
        )}
        <GridToCarouselWrapper>
          {repeatables.map(({ alt, image, title, subtitle, url }) => (
            <StyledLink key={title} href={url}>
              <Box position='relative' height='250px' mb='24px'>
                <NextImage
                  alt={alt}
                  fill
                  src={image}
                  width={230}
                  height={250}
                  imageStyle={css`
                    border-radius: ${({ theme }) => theme.radii.md};
                  `}
                />
              </Box>
              <Text fontWeight='bolder' size='l' mb='4px'>
                {title}
              </Text>
              <Text size='l' mb='0'>
                {subtitle}
              </Text>
            </StyledLink>
          ))}
        </GridToCarouselWrapper>
      </StyledSection>
    </StyledSectionWrapper>
  )
}

PopularDestinationsSection.propTypes = {
  staticFields: PropTypes.shape({
    anchor_id: PropTypes.string,
    background_color: PropTypes.oneOf(Object.keys(sectionBgColors)),
    display_default_contents: PropTypes.bool,
    title: PropTypes.string,
    subtitle: PropTypes.string,
  }).isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.string,
      alt: PropTypes.string,
      title: PropTypes.string,
      subtitle: PropTypes.string,
      url: PropTypes.string,
    }),
  ).isRequired,
  innerSectionProps: PropTypes.shape({}),
}

PopularDestinationsSection.defaultProps = {
  innerSectionProps: undefined,
}
