import React from 'react'
import PropTypes from 'helpers/proptypes'
import { css } from 'styled-components'
import { Section } from '@vizeat/components/es6/components/Section'
import { Box } from '@vizeat/components/es6/components/Box'
import { Flex } from '@vizeat/components/es6/components/Flex'
import { RichText } from 'prismic-reactjs'
import { StyledSectionWrapper, StyledSectionTitle } from './styled'
import { NextImage } from 'components/shared/NextImage'

const imageStyle = css`
  object-fit: cover;
  border-radius: ${({ theme }) => theme.radii.lg};
`

export function ArticleSection({ staticFields: { anchor_id: anchorId, title, description, image, alt } }) {
  return (
    <StyledSectionWrapper id={anchorId} bg='white'>
      <Section textAlign='left'>
        <Flex
          flexDirection={{ default: 'column', tablet: 'row' }}
          width='100%'
          gap={{ default: '32px', tablet: '48px' }}
        >
          <Box textAlign='left' width={{ tablet: '60%' }}>
            <StyledSectionTitle title={title} mb='24px' />
            <RichText render={description} />
          </Box>
          <Box position='relative' width={{ tablet: '40%' }} height={{ default: '300px', tablet: 'auto' }}>
            <NextImage src={image} alt={alt} fill imageStyle={imageStyle} />
          </Box>
        </Flex>
      </Section>
    </StyledSectionWrapper>
  )
}

ArticleSection.propTypes = {
  staticFields: PropTypes.shape({
    anchor_id: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.arrayOf(PropTypes.shape({})),
    image: PropTypes.string,
    alt: PropTypes.string,
  }).isRequired,
}
