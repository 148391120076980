import React from 'react'
import PropTypes from 'helpers/proptypes'
import { HeroSection } from 'components/shared/sections'
import { Section } from '@vizeat/components/es6/components/Section'
import { Heading } from '@vizeat/components/es6/components/Heading'
import { Flex } from '@vizeat/components/es6/components/Flex'
import { Divider } from '@vizeat/components/es6/components/Divider'
import { Text } from '@vizeat/components/es6/components/Text'
import { ChevronIconButton } from 'components/shared/buttons'
import { HERO_HEIGHT, heroSectionStyle } from './styled'

function SubtitlesBlock({ text, subtitle }) {
  const shouldShowDivider = subtitle && text
  if (!subtitle && !text) return null

  return (
    <>
      {shouldShowDivider && <Divider />}
      <Flex
        flexDirection='column'
        alignItems={shouldShowDivider ? 'flex-start' : 'center'}
        mt='16px'
        gap='8px'
        color='white'
        textAlign='left'
      >
        {subtitle && (
          <Heading as='h2' mb='0' color='white'>
            {subtitle}
          </Heading>
        )}
        {text && (
          <Text color='white' size='m'>
            {text}
          </Text>
        )}
      </Flex>
    </>
  )
}

SubtitlesBlock.propTypes = {
  text: PropTypes.string,
  subtitle: PropTypes.string,
}

SubtitlesBlock.defaultProps = {
  text: undefined,
  subtitle: undefined,
}

export function PrismicHeroSection({
  staticFields: {
    alt,
    anchor_id: anchorId,
    background_image: backgroundImage,
    button_text: buttonText,
    subtitle,
    text,
    title,
    to,
  },
  onCallToActionClick,
}) {
  return (
    <HeroSection
      backgroundImage={backgroundImage}
      alt={alt}
      height={{ default: HERO_HEIGHT.MOBILE, tablet: HERO_HEIGHT.TABLET }}
      pb='48px'
      mb='-72px'
      css={heroSectionStyle}
      id={anchorId}
    >
      <Section innerContainerStyles='padding: 0; z-index: 1;'>
        <Heading as='h1' type='hero' fontSize='40px' lineHeight='48px' mb='0' color='white'>
          {title}
        </Heading>
        <SubtitlesBlock text={text} subtitle={subtitle} />
        {buttonText && (
          <Flex mt='16px' justifyContent='center'>
            <ChevronIconButton href={to} onClick={onCallToActionClick}>
              {buttonText}
            </ChevronIconButton>
          </Flex>
        )}
      </Section>
    </HeroSection>
  )
}

PrismicHeroSection.propTypes = {
  staticFields: PropTypes.shape({
    anchor_id: PropTypes.string,
    alt: PropTypes.string,
    background_image: PropTypes.string,
    button_text: PropTypes.string,
    subtitle: PropTypes.string,
    text: PropTypes.string,
    title: PropTypes.string,
    to: PropTypes.string,
  }).isRequired,
  onCallToActionClick: PropTypes.func,
}

PrismicHeroSection.defaultProps = {
  onCallToActionClick: undefined,
}
