import React from 'react'
import PropTypes from 'helpers/proptypes'
import { useMediaQuery } from '@vizeat/components/es6/components/MediaQuery'
import { usePlaylist } from 'hooks/search/usePlaylist'
import { useSearchQuery } from 'hooks/search/useSearchQuery'
import { Section } from '@vizeat/components/es6/components/Section'
import { EventsSearchSection } from 'components/shared/sections'
import { SECTION_BG_COLOR, StyledSectionWrapper, StyledSectionTitle, StyledSectionSubtitle } from './styled'
import { RichText } from 'prismic-reactjs'
import { Box } from '@vizeat/components/es6/components/Box'

const { default: defaultBackgroundColor, ...sectionBgColors } = SECTION_BG_COLOR

export function PlaylistEventsSection({
  staticFields: {
    anchor_id: anchorId,
    title,
    description,
    subtitle,
    background_color: bgColor,
    playlist_id: playlistId,
  },
}) {
  const isTabletOrHigher = useMediaQuery('tablet')
  const { data, isSuccess } = usePlaylist(Number(playlistId))
  const searchPayload = data?.search || {}
  const { selectData } = useSearchQuery(searchPayload, { enabled: isSuccess })
  const events = selectData(({ events }) => events)

  // Avoid showing the section's title/description if no exp will be shown
  // The cached results of the search request will prevent data fetching in EventsSearchSection
  if (events.size === 0) return null

  return (
    <StyledSectionWrapper id={anchorId} bg={sectionBgColors[bgColor] || defaultBackgroundColor} textAlign='left'>
      <Section>
        <StyledSectionTitle title={title} />
        {description && (
          <Box mb='48px'>
            <RichText render={description} />
          </Box>
        )}
        <StyledSectionSubtitle>{subtitle}</StyledSectionSubtitle>
        <EventsSearchSection searchPayload={searchPayload} variant={isTabletOrHigher ? undefined : 'small'} />
      </Section>
    </StyledSectionWrapper>
  )
}

PlaylistEventsSection.propTypes = {
  staticFields: PropTypes.shape({
    anchor_id: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.arrayOf(PropTypes.shape({})),
    subtitle: PropTypes.string,
    background_color: PropTypes.oneOf(Object.keys(sectionBgColors)),
    playlist_id: PropTypes.string,
  }).isRequired,
}
