import React from 'react'
import PropTypes from 'helpers/proptypes'
import { useDefaultContents } from 'hooks/prismic'
import { SECTION_BG_COLOR, ICONS, StyledSectionWrapper, StyledSectionTitle } from './styled'
import { Section } from '@vizeat/components/es6/components/Section'
import { Grid } from '@vizeat/components/es6/components/Grid'
import { Box } from '@vizeat/components/es6/components/Box'
import { Flex } from '@vizeat/components/es6/components/Flex'
import { Text } from '@vizeat/components/es6/components/Text'
import { Heading } from '@vizeat/components/es6/components/Heading'
import { NextImage } from 'components/shared/NextImage'

const { default: defaultBackgroundColor, ...sectionBgColors } = SECTION_BG_COLOR
const { default: defaultIcon, ...icons } = ICONS

const defaultStaticFields = {
  background_color: 'whitish',
  title: __('HowItWorksSection::why choose eatwith?'),
}
const defaultItems = [
  {
    icon: 'certification',
    title: __('HowItWorksSection::We are absolute food experts'),
    text: __(
      'HowItWorksSection::Our mission is to connect the best local chefs and food connoisseurs around the world with adventurous travellers.',
    ),
  },
  {
    icon: 'cultural_immersion',
    title: __('HowItWorksSection::We are the leaders in social dining'),
    text: __(
      'HowItWorksSection::The best way to make new friends and learn the local culture first-hand when traveling.',
    ),
  },
  {
    icon: 'dish_and_hat',
    title: __('HowItWorksSection::We offer unique food experiences'),
    text: __(
      'HowItWorksSection::Try one of our immersive dinners, lunches, brunches, tastings, food tours and cooking classes with local hosts.',
    ),
  },
]

export function HowItWorksSection({ staticFields, innerSectionProps, items }) {
  const {
    statics: { anchor_id: anchorId, background_color: backgroundColor, title, subtitle } = {},
    repeatables = [],
  } = useDefaultContents({ staticFields, items, defaultStaticFields, defaultItems })

  if (repeatables.length === 0) return null

  return (
    <StyledSectionWrapper id={anchorId} bg={sectionBgColors[backgroundColor] || defaultBackgroundColor}>
      <Section textAlign={{ default: 'left', tablet: 'center' }} {...innerSectionProps}>
        {(title || subtitle) && (
          <Box mb='40px'>
            {title && <StyledSectionTitle title={title} />}
            {subtitle && (
              <Heading as='h3' type='medium'>
                {subtitle}
              </Heading>
            )}
          </Box>
        )}
        <Grid
          gridTemplateColumns={{ default: '1fr', tablet: 'repeat(3, 1fr)' }}
          gridTemplateRows={{ default: 'repeat(3, 1fr)', tablet: '1fr' }}
          alignItems='flex-start'
          gap='60px'
        >
          {repeatables.map(({ alt, icon, image, title, text }) => {
            // give priority to the image if provided otherwise fallback to the icon
            const Icon = icons[icon] || defaultIcon
            return (
              <Flex key={icon} flexDirection='column' alignItems='center' gap='16px' maxWidth='300px' mx='auto'>
                {image ? <NextImage src={image} alt={alt} width={50} height={50} /> : <Icon size='50px' />}
                <Box textAlign='center'>
                  <Heading as='h4' type='medium' mb='4px'>
                    {title}
                  </Heading>
                  <Text as='span' size='s'>
                    {text}
                  </Text>
                </Box>
              </Flex>
            )
          })}
        </Grid>
      </Section>
    </StyledSectionWrapper>
  )
}

HowItWorksSection.propTypes = {
  staticFields: PropTypes.shape({
    anchor_id: PropTypes.string,
    background_color: PropTypes.oneOf(Object.keys(sectionBgColors)),
    display_default_contents: PropTypes.bool,
    title: PropTypes.string,
    subtitle: PropTypes.string,
  }).isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      alt: PropTypes.string,
      icon: PropTypes.oneOf(Object.keys(icons)),
      image: PropTypes.string,
      text: PropTypes.string,
      title: PropTypes.string,
    }),
  ).isRequired,
  innerSectionProps: PropTypes.shape({}),
}

HowItWorksSection.defaultProps = {
  innerSectionProps: undefined,
}
