import { useCallback, useMemo } from 'react'
import { useTranslation } from 'next-i18next'

export function useDefaultContents({ staticFields, items, defaultStaticFields, defaultItems }) {
  const { t } = useTranslation()

  const handleTranslatableStrings = useCallback(
    (item) => {
      return Object.entries(item).reduce(
        (acc, [key, value]) => ({ ...acc, [key]: typeof value === 'string' ? t(value) : value }),
        {},
      )
    },
    [t],
  )

  const statics = useMemo(() => {
    if (staticFields.display_default_contents)
      return {
        anchor_id: staticFields.anchor_id,
        ...handleTranslatableStrings(defaultStaticFields),
      }
    return staticFields
  }, [defaultStaticFields, handleTranslatableStrings, staticFields])

  const repeatables = useMemo(() => {
    if (staticFields.display_default_contents) return defaultItems.map(handleTranslatableStrings)
    return items
  }, [defaultItems, handleTranslatableStrings, items, staticFields.display_default_contents])

  return { statics, repeatables }
}
