import React, { useState, useRef, useCallback } from 'react'
import PropTypes from 'helpers/proptypes'
import { HeroSection } from 'components/shared/sections'
import { Section } from '@vizeat/components/es6/components/Section'
import { Heading } from '@vizeat/components/es6/components/Heading'
import { Box } from '@vizeat/components/es6/components/Box'
import { Flex } from '@vizeat/components/es6/components/Flex'
import { LeadForm } from './LeadForm'
import { HERO_HEIGHT, heroSectionStyle } from './styled'

const FORM_OFFSET = 150
const HEADER_HEIGHT = 72

export function LeadFormSection({ staticFields }) {
  const formWrapper = useRef(null)
  const [nextSectionOffset, setNextSectionOffset] = useState(0)

  function toPx(number) {
    return `${number}px`
  }

  const handleNextSectionOffset = useCallback(() => {
    const formHeight = formWrapper.current?.offsetHeight || 0
    const heroHeight = parseInt(HERO_HEIGHT.MOBILE, 10)
    const cardHeightOutsideHero = formHeight - (heroHeight - FORM_OFFSET - HEADER_HEIGHT)
    const margin = cardHeightOutsideHero - HEADER_HEIGHT // due to the "top: -72px" assigned to the HeroSection
    setNextSectionOffset(margin)
  }, [])

  return (
    <HeroSection
      id={staticFields.anchor_id}
      backgroundImage={staticFields.background_image}
      alt={staticFields.alt}
      height={{ default: HERO_HEIGHT.MOBILE, tablet: HERO_HEIGHT.TABLET }}
      pb='0'
      mb={{ default: toPx(nextSectionOffset), tablet: toPx(-HEADER_HEIGHT) }}
      css={heroSectionStyle}
    >
      <Section innerContainerStyles='padding: 0; z-index: 1; height: 100%;'>
        <Flex
          flexDirection={{ default: 'column', tablet: 'row' }}
          gap={{ default: '24px', tablet: '0' }}
          alignItems={{ default: 'center', tablet: 'flex-end' }}
          textAlign='left'
          mt={{ default: toPx(FORM_OFFSET), tablet: '0' }}
          zIndex='1'
          width='100%'
          height={{ default: 'auto', tablet: '100%' }}
          ref={formWrapper}
        >
          <Box
            flex={{ default: 'none', tablet: '1' }}
            textAlign={{ default: 'center', tablet: 'left' }}
            mb={{ tablet: '28px' }}
          >
            <Heading as='h1' type='hero' color='white' fontSize='40px' lineHeight='48px' mb='0' flex={1}>
              {staticFields.title}
            </Heading>
          </Box>
          <Box
            position='relative'
            flex={{ default: 'none', tablet: '1' }}
            bottom={{ default: '0', tablet: toPx(-FORM_OFFSET) }}
            width='100%'
          >
            <LeadForm onResize={handleNextSectionOffset} />
          </Box>
        </Flex>
      </Section>
    </HeroSection>
  )
}

LeadFormSection.propTypes = {
  staticFields: PropTypes.shape({
    anchor_id: PropTypes.string,
    background_image: PropTypes.string,
    alt: PropTypes.string,
    title: PropTypes.string,
  }).isRequired,
}
