import { useTranslation } from 'next-i18next'
import { useMemo } from 'react'

export function usePressItems({ pressItems }) {
  const { t } = useTranslation()

  return useMemo(() => {
    if (pressItems.length > 0) return pressItems

    return [
      {
        url: 'https://www.vogue.com/article/eatwith-home-cooked-meal-airbnb-of-food-dining',
        image: '/assets/images/home-page/press/press_logo-vogue.svg',
        alt: 'Vogue logo',
        quote: t('Home::Press::Eatwith is revolutionizing the way we think about breaking bread.'),
      },
      {
        url: 'https://www.forbes.com/sites/larryolmsted/2017/05/06/if-you-love-food-and-travel-you-need-this-new-app-the-airbnb-of-local-dining/#12e9eea22719',
        image: '/assets/images/home-page/press/press_logo-forbes.svg',
        alt: 'Forbes logo',
        quote: t('Home::Press::If you love food and travel, you need this app.'),
      },
      {
        url: 'https://www.huffingtonpost.co.uk/entry/how-should-you-spend-your-downtime-this-evening_uk_5ac32fafe4b04646b645a012?guccounter=1',
        image: '/assets/images/home-page/press/press_logo-huffpost.svg',
        alt: 'Huffpost logo',
        quote: t('Home::Press::The sharing table experience – and a gorgeous local meal – whatever city you’re in.'),
        wide: true,
      },
      {
        url: 'https://www.lonelyplanet.com/news/2017/03/09/vizeat-connects-travellers-locals/',
        image: '/assets/images/home-page/press/press_logo-lonely_planet.svg',
        alt: 'Lonely place logo',
        quote: t('Home::Press::A unique way to exchange with people and discover new cultures around food.'),
      },
      {
        url: 'https://www.travelweekly.com/Luxury-Travel/Insights/Virtuoso-teams-with-Airbnb-of-food',
        image: '/assets/images/home-page/press/press_logo-travel_weekly.svg',
        alt: 'Travel weekly logo',
        quote: t('Home::Press::Eatwith provides the ability to bring destinations to life through food.'),
        wide: true,
      },
    ]
  }, [pressItems, t])
}
