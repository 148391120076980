import React from 'react'
import PropTypes from 'helpers/proptypes'
import { useMediaQuery } from '@vizeat/components/es6/components/MediaQuery'
import { NextImage } from 'components/shared/NextImage'
import { Section } from '@vizeat/components/es6/components/Section'
import { CarouselRtlSensitive } from 'components/shared/carousels'
import { SECTION_BG_COLOR, StyledSectionWrapper, StyledSectionTitle } from './styled'

export function TestimonialsSection({ items, staticFields: { anchor_id: anchorId, title } }) {
  const isTabletOrGreather = useMediaQuery('tablet')
  if (items.length === 0) return null

  return (
    <StyledSectionWrapper id={anchorId} bg={SECTION_BG_COLOR.grayish}>
      <Section textAlign='left'>
        <StyledSectionTitle customEndingMarks='.' title={title} mb='32px' />
        <CarouselRtlSensitive
          loop
          showBottomControls={false}
          showArrowControls
          slideWidth='200px'
          cellSpacing={isTabletOrGreather ? 80 : 40}
        >
          {items.map(({ logo_image_url: src, alt }) => (
            <NextImage key={src} src={Number(src)} alt={alt} width={200} height={80} />
          ))}
        </CarouselRtlSensitive>
      </Section>
    </StyledSectionWrapper>
  )
}

TestimonialsSection.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      logo_image_url: PropTypes.string,
      alt: PropTypes.string,
    }),
  ).isRequired,
  staticFields: PropTypes.shape({
    anchor_id: PropTypes.string,
    title: PropTypes.string.isRequired,
  }).isRequired,
}
