import React from 'react'
import PropTypes from 'helpers/proptypes'
import styled from 'styled-components'
import { usePressItems } from 'hooks/prismic'
import { StyledSection, StyledSectionWrapper } from 'components/pages/home/styled'
import { getPrismicImage } from 'helpers/ImageManager'
import { Press } from 'components/shared/prismic'

/* 
  TODO: merge the content of components/pages/home/styled.jsx and components/shared/prismic/styled.jsx 
  - The section heading between the 2 files is consisten
  - The private-events and travel-agents pages must adopt the spacing of the StyledSectionWrapper and StyledSection
  - The home page must import the PressSection component (used by the travel-agents as well) from components/shared/prismic/
  - The following CSS block must be moved to components/shared/prismic/styled.jsx
*/

const StyledPressSection = styled(StyledSection)`
  &:before,
  &:after {
    content: url(/assets/images/quote.svg);
    position: absolute;
    top: 0;
  }

  &:before {
    left: 24px;
  }

  &:after {
    display: none;
    right: 24px;
    transform: rotate(180deg);
  }

  ${({ theme }) => theme.media.phablet`
    &:after  { display: block }
  `}

  ${({ theme }) => theme.media.large`
    &:before { left: -24px }
    &:after  { right: -24px }
  `}
`

export function PressSection({ items }) {
  const pressItems = usePressItems({
    pressItems: items.map(({ image, link_url: url, ...others }) => ({
      image: getPrismicImage(image, '115x30'),
      url,
      ...others,
    })),
  })

  return (
    <StyledSectionWrapper bg='pressGreen'>
      <StyledPressSection pt={{ default: '56px', tablet: '64px' }} position='relative'>
        <Press items={pressItems} />
      </StyledPressSection>
    </StyledSectionWrapper>
  )
}

PressSection.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      alt: PropTypes.string,
      link_url: PropTypes.string.isRequired,
      image: PropTypes.string.isRequired,
      quote: PropTypes.string.isRequired,
      wide: PropTypes.bool,
    }),
  ),
}

PressSection.defaultProps = {
  items: [],
}
