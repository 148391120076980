import React, { useRef, useMemo, useState, useEffect, useCallback } from 'react'
import PropTypes from 'helpers/proptypes'
import { useTranslation } from 'next-i18next'
import { css } from 'styled-components'
import { useCurrency } from 'hooks/currencies/useCurrency'
import { useLocale } from 'hooks/locales/useLocale'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import moment from 'moment'
import { useMutation } from '@tanstack/react-query'
import { Box } from '@vizeat/components/es6/components/Box'
import { Flex } from '@vizeat/components/es6/components/Flex'
import { Heading } from '@vizeat/components/es6/components/Heading'
import { Text } from '@vizeat/components/es6/components/Text'
import { NumericInput } from '@vizeat/components/es6/components/NumericInput'
import { Select } from '@vizeat/components/es6/components/Select'
import { PhoneInput } from '@vizeat/components/es6/components/PhoneInput'
import { SearchDatesDropdown, OpenSearchInput } from 'components/shared/inputs'
import { Input, Error } from '@vizeat/components/es6/components/Input'
import { TextArea } from '@vizeat/components/es6/components/TextArea'
import { IconPaperAirplane } from '@vizeat/components/es6/assets/icons'
import { Checkbox } from '@vizeat/components/es6/components/Checkbox'
import { Alert } from '@vizeat/components/es6/components/Alert'
import calendarImage from '../../../../public/assets/images/icon_calendar.png'
import emailImage from '../../../../public/assets/images/icon_email.png'
import userImage from '../../../../public/assets/images/icon_people.png'
import searchImage from '../../../../public/assets/images/icn_search--gray.svg'
import { createLead } from 'api/leads'
import { GTM_FORM_TYPES, gtmFormInitiated, gtmFormSent, gtmFormFieldFilled } from 'gtm'
import { fetchGeoPlace } from 'helpers/places'

const MIN = 1
const MAX = 100

const inputStyle = css`
  padding-left: 60px;
  min-height: unset;
  height: 38px;
  border-radius: ${({ theme }) => theme.radii.full};
  font-size: 12px;
  color: ${({ theme }) => theme.colors.darkGray};
`

const inputContainerStyle = (icon) => css`
  margin-top: 0;

  & > div::before {
    content: '';
    position: absolute;
    top: 50%;
    margin: -12px 0 0 20px;
    width: 24px;
    height: 24px;
    z-index: 1;
    background: url(${icon}) no-repeat center;
  }

  ${Error} {
    margin-bottom: 0;
  }
`

const LeadSchema = Yup.object().shape({
  guestsNumber: Yup.number().positive().integer().required(__('FormField::Required field')),
  budget: Yup.number().positive().integer().required(__('FormField::Required field')),
  currency: Yup.number().positive().integer().required(__('FormField::Required field')),
  date: Yup.mixed().test({
    name: 'date',
    message: __('FormField::Required field'),
    test: (date) => {
      if (!date.from || !date.from.isValid()) return false
      return true
    },
  }),
  fullname: Yup.string().required(__('FormField::Required field')),
  phone: Yup.string(),
  email: Yup.string().email(__('FormField::Wrong format')).required(__('FormField::Required field')),
  message: Yup.string(),
  place: Yup.mixed().required(__('FormField::Required field')),
  want_to_receive_newsletter: Yup.boolean(),
})

export function LeadForm({ onResize }) {
  const { t } = useTranslation()
  const inputRef = useRef(null)
  const { locale } = useLocale()
  const { appCurrency, currencies } = useCurrency()
  const [searchInputValue, setSearchInputValue] = useState('')
  const { mutate, isError, isLoading, isSuccess } = useMutation(createLead)

  const formik = useFormik({
    initialValues: {
      guestsNumber: MIN,
      budget: undefined,
      currency: appCurrency.id,
      date: {
        from: undefined,
        to: undefined,
      },
      fullname: undefined,
      phone: '',
      email: '',
      message: '',
      place: undefined,
      want_to_receive_newsletter: true,
    },
    onSubmit: handleSubmit,
    validationSchema: LeadSchema,
    enableReinitialize: true,
  })

  const currenciesOptions = useMemo(
    () =>
      currencies.map(({ id, iso_3: iso3, symbol }) => ({
        value: id,
        label: `${iso3} (${symbol})`,
      })),
    [currencies],
  )

  const dateValue = useMemo(() => {
    const { from, to } = formik.values.date
    const dateFrom = from?.format('MM/DD') || ''
    const dateTo = to?.format('MM/DD') || ''
    if (from?.isSame(to)) return dateFrom
    return [dateFrom, dateTo].filter(Boolean).join(' - ')
  }, [formik.values.date])

  const handleChange = useCallback(
    (name, value) => {
      formik.setFieldTouched(name, true)
      formik.setFieldValue(name, value)
      gtmFormFieldFilled(GTM_FORM_TYPES.PE_LEAD_MODAL, { [name]: value })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formik.setFieldTouched, formik.setFieldValue],
  )

  const getInputError = useCallback(
    (inputName) => {
      if (formik.touched[inputName] && !!formik.errors[inputName]) return t(formik.errors[inputName])
      return undefined
    },
    [formik.errors, formik.touched, t],
  )

  const placeInputError = useMemo(() => getInputError('place'), [getInputError])

  // cache the fn to prevent firing the infinite loop in the useEffect of NumericInput
  const handleGuestsNumberChange = useCallback((value) => handleChange('guestsNumber', value), [handleChange])

  function handleBudgetBlur(e) {
    const budget = Number(e.target.value)
    if (!isNaN(budget)) handleChange('budget', parseInt(budget, 10))
  }

  function handleDateChange({ from, to }) {
    const mFromDate = from ? moment.utc(from) : undefined
    const mToDate = to ? moment.utc(to) : undefined
    handleChange('date', { from: mFromDate, to: mToDate })
  }

  async function handlePlaceChange({ id }) {
    const { locality, country_iso: countryISO } = await fetchGeoPlace({ id })
    handleChange('place', { locality, country_iso: countryISO })
  }

  function handlePhoneChange(value, isValid) {
    formik.setFieldError('phone', !isValid && __('PrivateEvents::LeadForm::Please enter a valid phone number'))
    formik.setFieldValue('phone', value, isValid)
    gtmFormFieldFilled(GTM_FORM_TYPES.PE_LEAD_MODAL, { phone: value })
  }

  function handlePhoneBlur() {
    formik.setFieldTouched('phone', true)
  }

  function handleSubmit({ budget, currency, date, guestsNumber, place, ...rest }) {
    const payload = {
      ...(date.from && { additional_info: { from_date: date.from, to_date: date.to } }),
      budget_per_person: budget,
      number_of_guests: parseInt(guestsNumber, 10),
      currency_id: currency,
      ...place,
      ...rest,
    }
    mutate({ payload })
  }

  function formikHandleChangeProxy(e) {
    formik.handleChange(e)
    gtmFormFieldFilled(GTM_FORM_TYPES.PE_LEAD_MODAL, { [e.target.name]: e.target.value })
  }

  useEffect(() => {
    gtmFormInitiated(GTM_FORM_TYPES.PE_LEAD_MODAL)
  }, [])

  useEffect(() => {
    if (isSuccess) gtmFormSent(GTM_FORM_TYPES.PE_LEAD_MODAL)
  }, [isSuccess])

  useEffect(() => {
    onResize()
    window.addEventListener('resize', onResize)
    return () => window.removeEventListener('resize', onResize)
  }, [onResize])

  useEffect(() => {
    const errorKeys = Object.keys(formik.errors)
    if (errorKeys.length === 0 || errorKeys.some((key) => formik.touched[key])) onResize()
  }, [formik.errors, formik.touched, isError, onResize])

  return (
    <Box
      position='relative'
      py='24px'
      px={{ default: '32px', tablet: '40px' }}
      bg='white'
      borderRadius='lg'
      boxShadow='sm'
      textAlign='start'
      width={{ default: '100%', tablet: '350px', desktop: '460px' }}
      mx='auto'
    >
      <Heading as='h2' type='xlarge' color='darkGray' locale={locale} customEndingMarks>
        {t('PrivateEvents::LeadForm::Let us organise your private event!')}
      </Heading>
      <Flex as='form' flexDirection='column' gap='8px' mt='32px' onSubmit={formik.handleSubmit}>
        <Flex flexDirection='row' alignItems='center' justifyContent='space-between'>
          <Text as='label' size='xs' htmlFor='guestsNumber' error={t(formik.errors.guestsNumber)}>
            {t('PrivateEvents::LeadForm::Number of guests *')}
          </Text>
          <NumericInput
            name='guestsNumber'
            min={MIN}
            max={MAX}
            value={formik.values.guestsNumber}
            onChange={handleGuestsNumberChange}
            height='32px'
            color='turquoise'
            rounded
            ref={inputRef}
          />
        </Flex>

        <Flex flexDirection='row' alignItems='center' gap='8px' justifyContent='space-between'>
          <Text as='label' size='xs' htmlFor='budget' error={!!getInputError('budget')}>
            {t('PrivateEvents::LeadForm::Budget per person')}
          </Text>
          <Box>
            <Flex flexDirection='row' alignItems='center' gap='8px'>
              <Select
                name='currency'
                onChange={({ value }) => handleChange('currency', value)}
                error={!!getInputError('currency')}
                options={currenciesOptions}
                value={formik.values.currency}
                containerStyles='margin: 0; & .ReactSelect__control { height: 38px!important; font-size: 12px!important; }'
                css={`
                  ${inputStyle}
                  padding-left: 0;
                  z-index: 13;
                `}
              />
              <Input
                name='budget'
                type='number'
                min={1}
                value={formik.values.budget}
                error={!!getInputError('budget')}
                onChange={formikHandleChangeProxy}
                onBlur={handleBudgetBlur}
                placeholder={t('PrivateEvents::LeadForm::ex: 50')}
                containerStyles='margin-top: 0; max-width: 150px;'
                css={`
                  ${inputStyle}
                  padding: 0 16px;
                `}
              />
            </Flex>
            {(!!getInputError('currency') || !!getInputError('budget')) && (
              <Flex mt='4px' justifyContent={formik.errors.currency ? 'flex-start' : 'flex-end'}>
                <Text as='span' color='red' size='s'>
                  {t(formik.errors.currency || formik.errors.budget)}
                </Text>
              </Flex>
            )}
          </Box>
        </Flex>

        <SearchDatesDropdown
          dropdownStyles='bottom: -8px; padding: 24px 8px;'
          dropdownPosition='center'
          Trigger={
            <Input
              name='date'
              error={getInputError('date')}
              placeholder={t('PrivateEvents::LeadForm::Date of the event')}
              containerStyles={inputContainerStyle(calendarImage)}
              css={inputStyle}
              value={dateValue}
              icon={emailImage}
              readOnly
            />
          }
          from={formik.values.date.from}
          to={formik.values.date.to}
          onChange={handleDateChange}
        />

        <Box width='100%'>
          <OpenSearchInput
            name='place'
            onChange={setSearchInputValue}
            onPlaceSelect={handlePlaceChange}
            placeholder={t('SearchForm::Where ?')}
            value={searchInputValue}
            shouldSearchEvents={false}
            shouldSearchUsers={false}
            width='100%'
            renderTrigger={(inputProps, comboboxProps, onClick) => (
              <Box {...comboboxProps}>
                <Input
                  name='place'
                  error={!!placeInputError}
                  containerStyles={inputContainerStyle(searchImage)}
                  css={inputStyle}
                  onClick={onClick}
                  {...inputProps}
                />
              </Box>
            )}
          />
          {!!placeInputError && <Error css='margin-bottom: 0;'>{t(placeInputError)}</Error>}
        </Box>

        <Input
          name='fullname'
          error={getInputError('fullname')}
          onChange={formikHandleChangeProxy}
          placeholder={t('PrivateEvents::LeadForm::Your name')}
          containerStyles={inputContainerStyle(userImage)}
          css={inputStyle}
        />

        <PhoneInput
          name='phone'
          error={getInputError('phone')}
          defaultCountryIso={locale}
          placeholder={t('PrivateEvents::LeadForm::Phone number')}
          onChange={handlePhoneChange}
          onBlur={handlePhoneBlur}
          value={formik.values.phone}
          containerStyles='margin-top: 0;'
          css={`
            ${inputStyle}
            padding: 0 10px;
            input.PhoneInputInput {
              font-size: 12px !important;
              height: 36px;
            }
          `}
        />

        <Input
          name='email'
          error={getInputError('email')}
          onChange={formikHandleChangeProxy}
          placeholder={t('PrivateEvents::LeadForm::Your email')}
          containerStyles={inputContainerStyle(emailImage)}
          css={inputStyle}
        />

        <TextArea
          name='message'
          error={getInputError('message')}
          onChange={formikHandleChangeProxy}
          placeholder={t('PrivateEvents::LeadForm::Write us a message')}
          rows={3}
          containerStyles='margin-top: 0;'
          css={`
            ${inputStyle}
            border-radius: ${({ theme }) => theme.radii.xl};
            height: unset;
            padding: 10px 16px;
          `}
        />

        <Checkbox
          id='want_to_receive_newsletter'
          label={t(
            "PrivateEvent::LeadModal::I'd like to receive Eatwith personalized recommendations, news and special offers in my inbox.",
          )}
          name='want_to_receive_newsletter'
          onChange={formik.handleChange}
          checked={formik.values.want_to_receive_newsletter}
          size='16px'
          parentStyles='align-items: flex-start; font-size: 12px; margin-top: 0;'
        />

        {isSuccess ? (
          <Alert type='success'>
            <Text as='span' fontWeight='bold' size='s'>
              {t('PrivateEvents::LeadForm::Enquiry sent! Our team will be in touch soon.')}
            </Text>
          </Alert>
        ) : (
          <Flex justifyContent='flex-end'>
            <Flex
              as='button'
              flexDirection='row'
              alignItems='center'
              gap='16px'
              borderRadius='xxl'
              bg='turquoise'
              color='white'
              border='none'
              p='8px 24px 8px 16px'
              fontWeight='medium'
              onClick={formik.handleSubmit}
              type='submit'
              disabled={isLoading}
            >
              <IconPaperAirplane size='20px' />
              {t('PrivateEvents::LeadForm::Send')}
            </Flex>
          </Flex>
        )}

        {isError && (
          <Alert type='error'>
            <Text as='span' fontWeight='bold' size='s'>
              {t('PrivateEvents::LeadForm::Please try again - we didn’t manage to save your information.')}
            </Text>
          </Alert>
        )}
      </Flex>
    </Box>
  )
}

LeadForm.propTypes = {
  onResize: PropTypes.func.isRequired,
}
