// libs
import React from 'react'
import PropTypes from 'helpers/proptypes'
import { useTranslation } from 'next-i18next'
import { useSocialMedia } from 'hooks/socials/useSocialMedia'
// components
import { EditorialTile } from '@vizeat/components/es6/components/EditorialTile'
import { Heading } from '@vizeat/components/es6/components/Heading'
import { Image } from '@vizeat/components/es6/components/Image'
import { Section } from '@vizeat/components/es6/components/Section'
import { Text } from '@vizeat/components/es6/components/Text'
import { SmartLink } from 'components/shared/SmartLink'
import { ButtonLink } from 'components/shared/ButtonLink'
import { Flex } from '@vizeat/components/es6/components/Flex'
import { Box } from '@vizeat/components/es6/components/Box'
import { Grid } from '@vizeat/components/es6/components/Grid'
import { useMediaQuery } from '@vizeat/components/es6/components/MediaQuery'

const defaultInstagramImages = [
  'https://screen-api.eatwith.com/files/1292928/-/scale_crop/200x200/center/-/progressive/yes/',
  'https://screen-api.eatwith.com/files/1292925/-/scale_crop/200x200/center/-/progressive/yes/',
  'https://screen-api.eatwith.com/files/1292929/-/scale_crop/200x200/center/-/progressive/yes/',
  'https://screen-api.eatwith.com/files/1292931/-/scale_crop/200x200/center/-/progressive/yes/',
  'https://screen-api.eatwith.com/files/1292927/-/scale_crop/200x200/center/-/progressive/yes/',
  'https://screen-api.eatwith.com/files/1292933/-/scale_crop/200x200/center/-/progressive/yes/',
  'https://screen-api.eatwith.com/files/1292930/-/scale_crop/200x200/center/-/progressive/yes/',
  'https://screen-api.eatwith.com/files/1292932/-/scale_crop/200x200/center/-/progressive/yes/',
  'https://screen-api.eatwith.com/files/1292926/-/scale_crop/200x200/center/-/progressive/yes/',
]

export function InstaBlogSection({
  id,
  backgroundColor,
  instagramSectionTitle,
  instagramSectionSubtitle,
  instagramSectionLink,
  instagramSectionImages,
  blogSectionTitle,
  blogSectionSubtitle,
  blogSectionLink,
  blogSectionImage,
  blogSectionBoxTitle,
  blogSectionBoxSubtitle,
  blogSectionBoxCallToAction,
}) {
  const { t } = useTranslation()
  const matchQuery = useMediaQuery('phablet')
  const instagramImages = [...instagramSectionImages, ...defaultInstagramImages].slice(0, 9)
  const { instagramLink: defaultInstagramLink } = useSocialMedia()
  const instagramLink = instagramSectionLink || defaultInstagramLink

  return (
    <Section id={id} background={backgroundColor}>
      <Box display={matchQuery ? 'flex' : 'block'}>
        <Box flex={2} mb={{ phablet: '0', default: '16px' }}>
          <SmartLink href={instagramLink} target='_blank' rel='noopener noreferrer'>
            <Heading>{t(instagramSectionTitle)}</Heading>
          </SmartLink>
          <Text italic>{t(instagramSectionSubtitle)}</Text>
          <Grid gap='8px' templateColumns='repeat(3, minmax(80px, 150px))'>
            {instagramImages.map((img, i) => (
              <SmartLink key={i} href={instagramLink} target='_blank' rel='noopener noreferrer'>
                <Image src={img} width='100%' alt='Instagram post' />
              </SmartLink>
            ))}
          </Grid>
        </Box>

        <Flex flexDirection='column' pl={{ phablet: '16px', default: '0' }} flex='3'>
          <Heading>{t(blogSectionTitle)}</Heading>
          <Text italic>{t(blogSectionSubtitle)}</Text>
          <EditorialTile flex='1'>
            <EditorialTile.Image src={blogSectionImage} />
            <EditorialTile.Card>
              <EditorialTile.Heading>{t(blogSectionBoxTitle)}</EditorialTile.Heading>
              <EditorialTile.Description>{t(blogSectionBoxSubtitle)}</EditorialTile.Description>
              <EditorialTile.ButtonWrapper>
                <ButtonLink href={blogSectionLink} rounded large lightOutline mobileFullWidth>
                  {t(blogSectionBoxCallToAction)}
                </ButtonLink>
              </EditorialTile.ButtonWrapper>
            </EditorialTile.Card>
          </EditorialTile>
        </Flex>
      </Box>
    </Section>
  )
}

InstaBlogSection.propTypes = {
  id: PropTypes.string,
  backgroundColor: PropTypes.string,
  instagramSectionTitle: PropTypes.string,
  instagramSectionSubtitle: PropTypes.string,
  instagramSectionLink: PropTypes.string,
  instagramSectionImages: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  blogSectionTitle: PropTypes.string,
  blogSectionSubtitle: PropTypes.string,
  blogSectionLink: PropTypes.string,
  blogSectionImage: PropTypes.string,
  blogSectionBoxTitle: PropTypes.string,
  blogSectionBoxSubtitle: PropTypes.string,
  blogSectionBoxCallToAction: PropTypes.string,
}

InstaBlogSection.defaultProps = {
  id: undefined,
  backgroundColor: 'light',
  instagramSectionTitle: __('Home::SocialSection::Follow @eatwith on Instagram'),
  instagramSectionSubtitle: __('Home::SocialSection::Tag us with #eatwith and double tap away!'),
  instagramSectionLink: undefined,
  instagramSectionImages: [],
  blogSectionTitle: __('Home::SocialSection::Bookmark the Eatwith blog'),
  blogSectionSubtitle: __('Home::SocialSection::Head to https://blog.eatwith.com/ for the latest food and travel news'),
  blogSectionLink: 'https://blog.eatwith.com/',
  blogSectionImage: '/assets/images/home-page/blog-thumb.jpg',
  blogSectionBoxTitle: __('Home::SocialSection::Eatwith Stories'),
  blogSectionBoxSubtitle: __(
    'Home::SocialSection::Get to know our incredible hosts and stock up on insider tips, recipes and travel inspo',
  ),
  blogSectionBoxCallToAction: __('Home::SocialSection::Read our blog'),
}
