import moment from 'moment'

function formatMomentDate(date) {
  return date.format('YYYY-MM-DD')
}

function getDateRangeFromPrismic(value) {
  if (value === '+1') {
    const tomorrow = moment.utc().add(1, 'days')
    return {
      start: formatMomentDate(tomorrow),
      end: formatMomentDate(tomorrow),
    }
  }

  if (value === '+7') {
    const today = moment.utc()
    return {
      start: formatMomentDate(today),
      end: formatMomentDate(today.clone().endOf('week')),
    }
  }

  return null
}

export function useFilters({ dateRangeFilters, mealtypeFilters }) {
  const dateRanges = dateRangeFilters
    ? dateRangeFilters
        .map(({ label, value }) => ({
          type: 'dateRange',
          value: getDateRangeFromPrismic(value),
          label,
        }))
        .filter(({ value }) => !!value)
    : null

  const mealtypes = mealtypeFilters
    ? mealtypeFilters.map(({ label, value }) => ({
        type: 'mealtypes',
        value: [value],
        label,
      }))
    : null

  return [dateRanges, mealtypes].reduce((acc, list) => (list ? acc.concat(list) : acc), [])
}
