import React from 'react'
import PropTypes from 'helpers/proptypes'
import { css } from 'styled-components'
import { useIsRtl } from 'hooks/locales/useIsRtl'
import { Section } from '@vizeat/components/es6/components/Section'
import { Box } from '@vizeat/components/es6/components/Box'
import { Flex } from '@vizeat/components/es6/components/Flex'
import { Heading } from '@vizeat/components/es6/components/Heading'
import { SECTION_BG_COLOR, StyledSectionWrapper, StyledButtonLink, StyledSectionTitle } from './styled'

const { default: defaultBackgroundColor, ...sectionBgColors } = SECTION_BG_COLOR

const buttonStyle = css`
  &::after {
    content: '';
    display: none;
    width: 90px;
    height: 90px;
    position: absolute;
    bottom: 0;
    left: -106px;
    background: url(/assets/images/arrow-spiral.png) center / cover no-repeat;
    transform: scaleX(${({ isRTL }) => (isRTL ? '-1' : '1')}) rotate(80deg);

    ${(props) => props.theme.media.tablet`
      display: ${({ showSpiralIcon }) => (showSpiralIcon ? 'block' : 'none')};
    `}
  }
`

export function CTASection({
  staticFields: {
    anchor_id: anchorId,
    background_color: backgroundColor,
    primary_cta_text: primaryCtaText,
    primary_cta_url: primaryCtaUrl,
    secondary_cta_text: secondaryCtaText,
    secondary_cta_url: secondaryCtaUrl,
    show_spiral_icon: showSpiralIcon,
    subtitle,
    title,
  },
  onCallToActionClick,
}) {
  const { isRTL } = useIsRtl()

  return (
    <StyledSectionWrapper id={anchorId} bg={sectionBgColors[backgroundColor] || defaultBackgroundColor}>
      <Section>
        {(title || subtitle) && (
          <Box mb={{ default: '24px', tablet: showSpiralIcon ? '48px' : '24px' }}>
            {title && <StyledSectionTitle title={title} />}
            {subtitle && (
              <Heading as='h3' type='medium'>
                {subtitle}
              </Heading>
            )}
          </Box>
        )}
        <Flex
          flexDirection={{ default: 'column', tablet: 'row' }}
          position='relative'
          width='100%'
          justifyContent='center'
          gap='16px'
        >
          <StyledButtonLink
            href={primaryCtaUrl}
            rounded
            large
            onClick={onCallToActionClick}
            showSpiralIcon={showSpiralIcon}
            isRTL={isRTL}
            css={buttonStyle}
          >
            {primaryCtaText}
          </StyledButtonLink>

          {secondaryCtaText && (
            <StyledButtonLink
              href={secondaryCtaUrl}
              rounded
              secondary
              large
              onClick={onCallToActionClick}
              showSpiralIcon={showSpiralIcon}
              isRTL={isRTL}
            >
              {secondaryCtaText}
            </StyledButtonLink>
          )}
        </Flex>
      </Section>
    </StyledSectionWrapper>
  )
}

CTASection.propTypes = {
  onCallToActionClick: PropTypes.func,
  staticFields: PropTypes.shape({
    anchor_id: PropTypes.string,
    background_color: PropTypes.oneOf(Object.keys(sectionBgColors)),
    primary_cta_text: PropTypes.string,
    primary_cta_url: PropTypes.string,
    secondary_cta_text: PropTypes.string,
    secondary_cta_url: PropTypes.string,
    show_spiral_icon: PropTypes.bool,
    subtitle: PropTypes.string,
    title: PropTypes.string,
  }).isRequired,
}

CTASection.defaultProps = {
  onCallToActionClick: undefined,
}
