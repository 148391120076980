import React from 'react'
import PropTypes from 'helpers/proptypes'
import styled, { css } from 'styled-components'
import { Image } from '@vizeat/components/es6/components/Image/Image'
import { CarouselRtlSensitive } from 'components/shared/carousels'
import { useMediaQuery } from '@vizeat/components/es6/components/MediaQuery'
import { Grid } from '@vizeat/components/es6/components/Grid'
import { rgba } from 'polished'
import { Box } from '@vizeat/components/es6/components/Box'

const StyledImage = styled(({ wide, ...props }) => <Image {...props} />)`
  max-width: ${(props) => (props.wide ? '180px' : '100%')};
  height: auto;
  align-self: center;
`

const carouselControlsStyles = css`
  button {
    background: ${({ theme }) => rgba(theme.colors.darkGreen, 0.25)};

    &[aria-selected='true'] {
      background: ${({ theme }) => theme.colors.darkGreen};
    }
  }
`
export function Press({ items }) {
  const isPhabletOrHigher = useMediaQuery('phablet')

  return (
    <CarouselRtlSensitive
      showArrowControls={isPhabletOrHigher}
      showBottomControls={!isPhabletOrHigher}
      slideWidth={isPhabletOrHigher ? '238px' : '100%'}
      dotStyles={carouselControlsStyles}
    >
      {items.map((item) => (
        <Grid
          as='a'
          key={item.url}
          minHeight='115px'
          gridTemplateRows='80px auto'
          justifyItems='center'
          alignItems='flex-start'
          href={item.url}
          target='_blank'
          rel='noopener noreferrer'
        >
          {item.image && <StyledImage src={item.image} wide={item.wide} alt={item.alt || ''} />}
          {item.quote && (
            <Box as='blockquote' fontWeight='italic' fontSize='14px' border='none' m='0' p='0' color='initial'>
              {item.quote}
            </Box>
          )}
        </Grid>
      ))}
    </CarouselRtlSensitive>
  )
}

Press.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      alt: PropTypes.string,
      url: PropTypes.string,
      image: PropTypes.string,
      quote: PropTypes.string,
      wide: PropTypes.bool,
    }).isRequired,
  ).isRequired,
}
